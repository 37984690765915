<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmGpathFormData && Object.keys(vmGpathFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationcounsellor_id">{{ cvCounsellorLabel }}</label>:&nbsp;&nbsp;<span>{{ propCounsellor.user_name }}</span>
                </div>

                <!-- Search for a GIDE user using email, name or mobile -->
                <!-- <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                  <label for="validationslot_duration">{{ cvSearchGideUser }}</label>
                  <input v-model="vmGpathFormData.search_field" type="text" class="form-control" required/>
                  <button type="button" class="btn btn-primary ml-1 mt-2" @click="searchGideUser()" :disabled="!disableGetCode && vmGpathFormData.attach_aff_code">Search</button>
                </div> -->
                <!-- Search for a GIDE user using email, name or mobile -->

                <div class="col-md-12 mb-3">
                  <label for="validationslot_duration">{{ cvStudentNameLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                  <input v-model="vmGpathFormData.student_name" type="text" class="form-control" required/>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationslot_duration">{{ cvStudentEmailLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
                  <input v-model="vmGpathFormData.stu_email" type="text" class="form-control" required />
                </div>

                <!-- Subscription Plan Dropdown -->
                <div class="col-md-12 mb-3" v-if="propProductCode == GideProducts.TESTSERIES">
                  <label for="validationslot_duration">{{ cvSubscriptionPlan }}</label>&nbsp;
                  <select v-model="vmGpathFormData.subs_plan_id" class="form-control">
                    <option disabled value="">Please select one</option>
                    <option v-for="item in planIdList" :value="item.subs_plan_id" :key="item.subs_plan_id">{{ item.subs_plan_name }}&nbsp;-&nbsp;{{item.subs_plan_price}}</option>
                  </select>
                </div><!-- Subscription Plan Dropdown -->

                <p class="ml-1">
                  <button type="button" class="btn btn-primary" :disabled="disableGetCode" @click="generateAffLink()">{{ cvGetCodeBtn }}</button>&nbsp;&nbsp;:&nbsp;&nbsp;
                  <span v-if="vmGpathFormData.aff_code" style="vertical-align: middle; color: #FFF; background: #e5252c !important; font-size: 18px;">
                    {{vmGpathFormData.aff_code}}
                  </span>
                </p>
                <!--Generate discount code for other products  -->
                <!-- <div class="col-md-12 mb-3">
                  <label for="validationslot_duration">{{ cvAttachCodeToOthrPdts }}</label><br>
                  <b-badge
                    style="cursor:pointer;border-color:#e5252c;"
                    class="border mr-2 mb-2 font-weight-normal badge-pill badge-primary"
                    variant="none"
                    >
                    xxx
                  </b-badge>
                </div>Generate discount code for other products  -->

                <div class="col-md-12 mb-3" v-if="!propProductCode">
                  <label for="validationslot_duration">{{ cvSelectProduct }}</label>
                  <select :value="vmGpathFormData.source" class="form-control">
                    <option :value="src" v-for="(pdt, src) in GideProducts" :key="pdt">{{pdt}}</option>
                  </select>
                </div>

                <div class="col-md-12 mb-3" v-if="propProductCode == GideProducts.COUNSELLING">
                  <label for="validation_chooseDate">Choose Slot</label>
                  <multiselect
                    v-model="vmGpathFormData.slot"
                    class=""
                    :options="availableSlotList"
                    :custom-label="sessionCustomLabel"
                    id="validation_chooseDate">
                  </multiselect>
                </div>
              </div>
            </form>
            <p>
              <!-- <button v-if="vmGpathFormData.attach_aff_code" type="button" class="btn btn-primary ml-1" @click="createSession()" :disabled="!disableGetCode && vmGpathFormData.attach_aff_code">{{ cvSendEmailBtn }}</button> -->
              <button v-if="vmGpathFormData.attach_aff_code" type="button" class="btn btn-primary ml-1" @click="inviteUser()" :disabled="!disableGetCode && vmGpathFormData.attach_aff_code">{{ cvSendEmailBtn }}</button>
              <!-- <button v-else type="button" class="btn btn-primary ml-1" @click="createSession()">Invite</button> -->
              <button v-else type="button" class="btn btn-primary ml-1" @click="inviteUser()">Invite</button>
            </p>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { Affiliates } from "../FackApi/api/Affiliate"
import { Spcms } from "../FackApi/api/Spcm"
import { SpcmCalenders } from "../FackApi/api/SpcmCalender"
import Multiselect from "vue-multiselect"
import moment from "moment"
import { events } from "../FackApi/api/events"
import ApiResponse from "../Utils/apiResponse"
import { GPathSessions } from "../FackApi/api/GPathSession"
import { TprepSessions } from "../FackApi/api/TprepSession"
import { SubscriptionPlans } from "../FackApi/api/Subscription/subscriptionPlan"
import GideProducts from "../FackApi/json/GideProducts.json"
import TestSeriesConfigJson from "../FackApi/json/TestSeries.json"

export default {
  name: "InviteStudent",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCounsellor: {
      type: Object,
      default: function () {
        return {
          user_name: ""
        }
      }
    },
    propProductCode: {
      type: String,
      default: null
    },
    propProductName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      TestSeriesConfigJson: TestSeriesConfigJson,
      cvCardTitle: "Invite Student",
      cvGetCodeBtn: `Get ${this.propProductName} Discount Code`,
      cvSendEmailBtn: "Attach & Invite",
      sessionList: [],
      slotDate: "",
      slotTime: "",
      cvCounsellorLabel: "Counsellor Name",
      cvSlotStartTsLabel: "Time",
      cvDateLabel: "Date",
      cvSlotDurationLabel: "Duration",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      availableSlotList: [],
      toastVariant: "default",
      toastTitle: "Invite User",
      vmGpathFormData: Object.assign({}, this.initFormData()),
      vmStartDate: "",
      minStartDate: new Date(),
      convertedDateTime2: "",
      convertedDateTime: "",
      cvStudentEmailLabel: "Student Email",
      cvAffiliateLinkLabel: "Discount Code",
      cvSelectProduct: "Select a GIDE Module",
      cvStudentNameLabel: "Student Name",
      cvSearchGideUser: "Search Gide user",
      cvAttachCodeToOthrPdts: "Attach code to other products",
      gpsId: null,
      cvAttachAffCode: "Get Discount code",
      affiliateCodeObj: null,
      disableGetCode: false,
      cvSubscriptionPlan: "TestSeries Plan",
      planIdList: [],
      GideProducts: GideProducts
    }
  },
  components: {
    Multiselect
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    // this.getAvailableSlots(this.propCounsellor)
    if (this.propProductCode == GideProducts.COUNSELLING) { this.getAvailableSlots(this.propCounsellor) }
    if (this.propProductCode == this.GideProducts.TESTSERIES) { this.getPlanNames() }
  },
  methods: {
    /* eslint-enable */
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGpathFormData) {
          if (!this.vmGpathFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error(
          "Exception occurred at validate() and Exception:",
          err.message
        )
        return null
      }
    },
    /**
     * sessionCustomLabel
     */
    /*eslint-disable */
    sessionCustomLabel ({ slot_start_ts, slot_duration }) {
      let startTime = moment.unix(slot_start_ts).format("MMMM Do YYYY, h:mm:ss a")
      let endTime = moment.unix(slot_start_ts + slot_duration).format("MMMM Do YYYY, h:mm:ss a")
      return `${startTime} - ${endTime}`
    },
    /**
     * generateAffLink
     */
    async generateAffLink () {
      if (this.vmGpathFormData.stu_email == "") {
        this.toastTitle = "Invite User"
        this.toastMsg = "User Email Id is Missing"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }      

      // Since the button to generate code is clicked set the affiliated flag to true
      this.vmGpathFormData.attach_aff_code = true
      /**
       As of now the only product that is affiliated, is GPATHS so hardcoding the product_name to GPATHS, because the module_name in affiliate table needs to be 
       the product_name(e.g. GPATHS, SPCMCOUNCEL etc).
       todo:- We have to create a propper system to get other products on GIDE, affiliated
       */
      const getCodeObj = {
        aff_entity_id: this.propCounsellor.user_id, 
        user_email: this.vmGpathFormData.stu_email, 
        product_name: this.propProductCode
      }
      let affiliateResp = await Affiliates.AffiliateGetCode(this, getCodeObj)

      if (!affiliateResp.resp_status) {
        this.toastTitle = "Invite User"
        this.toastMsg = affiliateResp.resp_msg
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      this.affiliateCodeObj = affiliateResp.resp_data.data
      this.vmGpathFormData.aff_code = this.affiliateCodeObj.aff_code
      this.disableGetCode = true
    },
    /**
     * getAvailableSlots
     */
    async getAvailableSlots (counsellor) {
      this.availableSlotList = []
      let payload = {
        counsellor_id: counsellor.user_id
      }
      this.counsellorId = counsellor.user_id

      const sessionListApiResp = await SpcmCalenders.spcmCalendarList(this, payload)
      if (sessionListApiResp.resp_status) {
        this.availableSlotList = sessionListApiResp.resp_data.data

        if (this.vmGpathFormData.slot && typeof this.vmGpathFormData.slot == "number") {
          const findIndex = this.availableSlotList.findIndex(ele => ele.slot_start_ts == this.vmGpathFormData.slot)
          if (findIndex >= 0) {
            this.vmGpathFormData.slot = this.availableSlotList[findIndex]
          }
        }
      }
      else {
        this.showToast = true
        this.toastVariant = "warning"
        this.toastMsg = "Looks like no calendar dates are available."
      }
    },
    /**
     * 
     */
    async inviteUser () {
      try {
        if (this.affiliateCodeObj) {
          this.vmGpathFormData.aff_id = this.affiliateCodeObj.aff_id
          this.vmGpathFormData.aff_entity_id = this.propCounsellor.user_id
        }
        this.module_name = this.propProductCode
        this.vmGpathFormData.module_name = this.propProductCode
        let response = null

        switch(this.module_name) {
          case this.GideProducts.COUNSELLING:
            response = await Spcms.spcmStudentPublicReg(this, this.vmGpathFormData)
          break

          case this.GideProducts.GPATHS:
            response = await GPathSessions.gpath_invite(this, this.vmGpathFormData)
          break

          case this.GideProducts.TESTSERIES:
            response = await TprepSessions.tprep_invite(this, this.vmGpathFormData)
          break

          default:
            console.error(`${this.module_name} is not available to invite`)
            return
        }

        this.toastTitle = "Invite User"
        this.toastMsg = response.resp_msg
        this.toastVariant = response.resp_status ? "success" : "danger"
        this.showToast = true

        if (this.propProductCode == this.GideProducts.COUNSELLING && response.resp_status) {
          this.$emit("emitCloseInviteUser", response, this.vmGpathFormData.slot)
        }
        else if (response.resp_status) {
          this.$emit("emitCloseInviteUser", response)
        }
      }
      catch (err) {
        console.error("Exception in inviteToGpath: ", err)
      }
    },
    /**
     * searchGideUser
    */
    async searchGideUser () {
      const searchGideUserResp = await events.eventSearchUser(this, { search: this.vmGpathFormData.search_field })
      if (searchGideUserResp.resp_status) {
        let userDetails = searchGideUserResp.resp_data
        this.vmGpathFormData.user_id = userDetails.user_id
        this.vmGpathFormData.student_name = userDetails.user_name
        this.vmGpathFormData.stu_email = userDetails.user_email

        searchGideUserResp.resp_msg = "User found"
        await ApiResponse.responseMessageDisplay(this, searchGideUserResp)
        return
      }
      await ApiResponse.responseMessageDisplay(this, searchGideUserResp)
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        counsellor_id: this.propCounsellor.user_id,
        student_name: "",
        stu_first_name: "",
        stu_last_name: "",
        stu_email: "",
        user_type: "Student",
        product_name: GideProducts.GPATHS,
        aff_code: ""
      }
    },
    /**
     * getPlanNames
     */
    async getPlanNames () {
      try {
        const subsPlanListResp = await SubscriptionPlans.subscriptionPlanList(this, { subs_plan_type: TestSeriesConfigJson.TESTSERIES_SUBS_PLAN_TYPE })
        if (subsPlanListResp.resp_status) {
          this.planIdList = subsPlanListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Error in getPlanNames ", err)
      }
    },
  }
}
</script>
